import classNames from "classnames";
import { Link } from "gatsby";
import React from "react";

import { LockGradientIcon } from "../../images/icons";
import { ReactComponent as ArrowLeftIcon } from "../../images/icons/arrow-left.svg";
import { ReactComponent as BookIcon } from "../../images/icons/book.svg";

interface IHeroCardProps {
  url: string;
  title: string;
  order: number;
  isBlocked?: boolean;
  isNext?: boolean;
}

const HeroCard: React.FC<IHeroCardProps> = ({
  url,
  title,
  order,
  isBlocked,
  isNext,
}) => {
  const Icon = isBlocked
    ? LockGradientIcon.bind(null, {
        colorStart: "var(--color-red-600)",
        colorEnd: "var(--color-pink-600)",
      })
    : BookIcon;

  return (
    <Link
      to={url}
      className={classNames(
        "w-[194px] h-[194px] bg-white rounded-2xl p-6 flex flex-col items-center justify-between gap-3",
        {
          "ml-auto": isNext,
        }
      )}
    >
      <div className="flex flex-col gap-3 items-center">
        <Icon className="w-6 h-6 text-grey-lite" />
        <span className="text-xs font-semibold text-center text-grey-lite">
          Lesson #{order}
        </span>
        <span className="text-sm font-semibold text-black-light text-center">
          {title}
        </span>
      </div>
      <ArrowLeftIcon
        className={classNames("w-6 h-6 green-icon", {
          "rotate-180": isNext,
        })}
      />
    </Link>
  );
};

export default HeroCard;
