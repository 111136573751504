import { Listbox, Transition } from "@headlessui/react";
import { Link } from "gatsby";
import React, { useEffect } from "react";

import useBoolean from "../../hooks/useBoolean";

import Button from "../UI/Button";
import { ReactComponent as CloseIcon } from "../../images/icons/close.svg";
import { GradientHamburgerIcon } from "../../images/icons";

import "./Contents.scss";
import classNames from "classnames";

interface IContentsProps {
  pathname: string;
  contents: Array<{
    title: string;
    url: string;
  }>;
}

const Contents: React.FC<IContentsProps> = ({ contents, pathname }) => {
  const [isOpenedList, setOpenedList] = useBoolean(true);
  const [isHoverBtn, setHoverBtn] = useBoolean();

  useEffect(() => {
    setOpenedList.set(window.innerWidth >= 1024);
  }, []);

  return (
    <div className="w-full">
      <Button
        type="outline"
        color="red"
        onHover={setHoverBtn.on}
        onLeave={setHoverBtn.off}
        StartIcon={isOpenedList ? CloseIcon : GradientHamburgerIcon}
        iconProps={
          isOpenedList
            ? {
                className: `!ml-0 !mr-[18px] ${!isHoverBtn ? "gray-icon" : ""}`,
              }
            : {
                className: `!w-6 !h-6 ${isHoverBtn ? "reset-icon" : ""}`,
                colorStart: "var(--color-red-600)",
                colorEnd: "var(--color-pink-600)",
              }
        }
        label="Lesson Overview"
        className="text-black-light !rounded-lg !border !w-full !text-sm"
        onClick={setOpenedList.toggle}
      />
      <Listbox value={0} onChange={() => {}}>
        <Transition
          show={isOpenedList}
          className="pt-4 w-full"
          enter="transition duration-100 ease-out"
          enterFrom="transform h-0 opacity-0"
          enterTo="transform h-full opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform h-full opacity-100"
          leaveTo="transform h-0 opacity-0"
        >
          <Listbox.Options className="space-y-4 py-4" static>
            {contents.map((content) => {
              const isActive =
                typeof window !== "undefined" &&
                window.location.href.includes(content.url);

              return (
                <Link
                  key={content.url}
                  to={`${pathname}${content.url}`}
                  className={classNames("block text-sm text-grey-400", {
                    "custom-bullet": !isActive,
                    "custom-bullet-active !text-white font-semibold": isActive,
                  })}
                >
                  {content.title}
                </Link>
              );
            })}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};

export default Contents;
