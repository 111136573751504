import { Link } from "gatsby";
import React, { useContext } from "react";

import { ReactComponent as BookIcon } from "../../images/icons/book.svg";
import { ReactComponent as ArrowLeftIcon } from "../../images/icons/arrow-left.svg";
import HeroCard from "./HeroCard";
import { AuthContext } from "../../contexts/AuthContext";

interface IHeroProps {
  title: string;
  categoryName: string;
  order: number;
  backgroundColor?: string[];
  categorySlug: string;
  nextLesson: {
    title: string;
    url: string;
    order: number;
    visibility: string;
  };
  prevLesson: {
    title: string;
    url: string;
    order: number;
    visibility: string;
  };
}

const Hero: React.FC<IHeroProps> = ({
  title,
  categoryName,
  order,
  backgroundColor,
  categorySlug,
  nextLesson,
  prevLesson,
}) => {
  const { isAuthenticated, subscription } = useContext(AuthContext);

  return (
    <div
      className="lg:pt-[134px] lg:pb-[38px] pb-11 pt-[96px] rounded-bl-[45px] rounded-br-[45px] xl:rounded-br-[100px] xl:rounded-bl-[100px] px-6"
      style={{
        background: `linear-gradient(${
          backgroundColor?.length ? "90deg" : "120deg"
        }, ${backgroundColor?.[0] ?? "var(--color-purple-500)"} 0%, ${
          backgroundColor?.[1] ?? "var(--color-purple-600)"
        } 100%)`,
      }}
    >
      <div className="flex justify-between lg:flex-row flex-col gap-5 max-w-[1140px] mx-auto w-full">
        <div className="space-y-6">
          <Link
            to={`/learn/categories/${categorySlug}`}
            className="flex items-center gap-2.5"
          >
            <ArrowLeftIcon className="w-6 h-6 text-white" />
            <span className="text-white text-sm font-semibold">
              Course Overview
            </span>
          </Link>
          <div className="space-y-2">
            <span className="text-white text-lg font-semibold">
              {categoryName}
            </span>
            <h1 className="lg:text-[44px] text-[32px] font-bold text-white">
              {title}
            </h1>
          </div>
          <div className="flex items-center gap-3">
            <BookIcon className="text-white" />
            <span className="text-white font-semibold text-base">
              Lesson #{order}
            </span>
          </div>
        </div>
        <div className="hidden md:flex lg:max-w-[400px] w-full flex-wrap gap-3">
          {prevLesson?.url && (
            <HeroCard
              {...prevLesson}
              isBlocked={
                (prevLesson.visibility === "members" && !isAuthenticated) ||
                (prevLesson.visibility === "premium" && !subscription)
              }
            />
          )}
          {nextLesson?.url && (
            <HeroCard
              {...nextLesson}
              isNext
              isBlocked={
                (nextLesson.visibility === "members" && !isAuthenticated) ||
                (nextLesson.visibility === "premium" && !subscription)
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
