import React, { useState } from "react";
import Modal from "react-modal";
/* 
Add in MDX file
<YouTubeEmbed url="https://www.youtube.com/embed/pWk0bG5NMPo" title="What Is The Modern Data Stack"></YouTubeEmbed>
*/
const customStyles = {
  overlay: {
    position: "fixed",
    inset: "0px",
    backgroundColor: "rgb(18 16 16 / 75%)",
    zIndex: 99,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const YouTubeEmbed: React.FC<{ title: string; url: string }> = ({
  url,
  title,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="w-full flex items-center h-[60px] justify-between px-4 bg-gradient-to-r from-red-600 to-pink-600 rounded-[12px]"
      >
        <div className="font-[450] text-base text-black">
          <div className="flex items-center">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="15" r="15" fill="white" />
              <path
                d="M19.5708 14.1425C20.2182 14.5309 20.2182 15.4691 19.5708 15.8575L12.5145 20.0913C11.848 20.4912 11 20.0111 11 19.2338L11 10.7662C11 9.9889 11.848 9.50878 12.5145 9.9087L19.5708 14.1425Z"
                fill="url(#paint0_linear_2724_14308)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2724_14308"
                  x1="1.67638e-07"
                  y1="15"
                  x2="30"
                  y2="15"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="var(--color-red-600)" />
                  <stop offset="1" stopColor="var(--color-pink-600)" />
                </linearGradient>
              </defs>
            </svg>
            <div className="ml-4 text-white">
              Video: <strong className="!text-white">“{title}”</strong>
            </div>
          </div>
        </div>
        <span className="text-white text-sm">Watch Now!</span>
      </button>
      {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles as any}
      >
        {/* <div className="absolute top-1 right-1 border-none cursor-pointer" onClick={() => setIsOpen(false)}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.21094 14.7891L15.0001 0.999907" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14.7891 14.9983L0.999907 1.20911" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div> */}
        <div className="player-box">
          <iframe
            width="560"
            height="315"
            src={
              url
                ? url.replace("watch?v=", "embed/")
                : "//www.youtube.com/embed/pWk0bG5NMPo"
            }
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </>
  );
};

export default YouTubeEmbed;
