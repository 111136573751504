import { Listbox, Transition } from "@headlessui/react";
import { Link } from "gatsby";
import React, { useContext, useEffect } from "react";

import useBoolean from "../../hooks/useBoolean";

import { ILesson } from "../../templates/category";
import Button from "../UI/Button";
import { ReactComponent as BookIcon } from "../../images/icons/book.svg";
import { ReactComponent as ClockIcon } from "../../images/icons/clock.svg";
import { ReactComponent as CloseIcon } from "../../images/icons/close.svg";
import {
  GradientHamburgerIcon,
  LockGradientIcon,
  PlayGradientIcon,
} from "../../images/icons";
import { AuthContext } from "../../contexts/AuthContext";
import classNames from "classnames";

interface ILessonPaginationProps {
  lessons: Array<{
    frontmatter: ILesson;
  }>;
  currentLesson: number;
}

const LessonPagination: React.FC<ILessonPaginationProps> = ({
  currentLesson,
  lessons,
}) => {
  const [isOpenedList, setOpenedList] = useBoolean(true);
  const [isHoverBtn, setHoverBtn] = useBoolean();
  const { isAuthenticated, subscription } = useContext(AuthContext);

  useEffect(() => {
    setOpenedList.set(window.innerWidth >= 1024);
  }, []);

  return (
    <div className="w-full">
      <Button
        type="outline"
        color="red"
        onHover={setHoverBtn.on}
        onLeave={setHoverBtn.off}
        StartIcon={isOpenedList ? CloseIcon : GradientHamburgerIcon}
        iconProps={
          isOpenedList
            ? {
                className: `!ml-0 !mr-[18px] ${!isHoverBtn ? 'gray-icon' : ''}`,
              }
            : {
                className: `!w-6 !h-6 ${isHoverBtn ? 'reset-icon' : ''}`,
                colorStart: "var(--color-red-600)",
                colorEnd: "var(--color-pink-600)",
              }
        }
        label="Course Overview"
        className="text-black-light !rounded-lg !border !w-full !text-sm"
        onClick={setOpenedList.toggle}
      />
      <Listbox value={currentLesson.toString()} onChange={() => {}}>
        <Transition
          show={isOpenedList}
          className="pt-4 w-full lg:pb-[29px]"
          enter="transition duration-100 ease-out"
          enterFrom="transform h-0 opacity-0"
          enterTo="transform h-full opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform h-full opacity-100"
          leaveTo="transform h-0 opacity-0"
        >
          <Listbox.Options className="space-y-2" static>
            {lessons.map((lesson, idx) => {
              const hours = (
                lesson.frontmatter.time_to_learn ?? 0 / 60
              ).toFixed(0);
              const minutes = (
                lesson.frontmatter.time_to_learn ?? 0 % 60
              ).toFixed(0);

              const isActive = currentLesson === idx;
              const isBlocked =
                (lesson.frontmatter.visibility === "members" &&
                  !isAuthenticated) ||
                (lesson.frontmatter.visibility === "premium" && !subscription);

              return (
                <Link
                  to={`/learn/${lesson.frontmatter.category}/lessons/${lesson.frontmatter.slug}`}
                  style={{
                    background: isActive
                      ? "linear-gradient(#EBFAF1, #EBFAF1) padding-box, linear-gradient(to right, var(--color-green-500), var(--color-green-600)) border-box"
                      : undefined,
                  }}
                  className={classNames(
                    "flex gap-4 pl-4 py-2 pr-3 border rounded-lg border-grey-100 bg-white hover:border-grey-200",
                    {
                      "bg-grey-300 border-0": isBlocked && !isActive,
                      "relative !border-transparent !border": isActive,
                    }
                  )}
                  key={lesson.frontmatter.slug}
                >
                  <div className="w-6 h-6 flex items-center justify-center">
                    {!isBlocked && !isActive && (
                      <BookIcon className="w-4 h-4 text-grey-lite" />
                    )}
                    {isBlocked && !isActive && (
                      <LockGradientIcon
                        className="w-4 h-4"
                        colorStart="var(--color-red-600)"
                        colorEnd="var(--color-pink-600)"
                      />
                    )}
                    {isActive && (
                      <PlayGradientIcon
                        className="w-4 h-4"
                        colorStart="var(--color-green-500)"
                        colorEnd="var(--color-green-600)"
                      />
                    )}
                  </div>
                  <div className="w-full space-y-1">
                    <span className="block text-sm font-semibold text-black-light">
                      {lesson.frontmatter.title}
                    </span>
                    <div className="flex justify-between items-center">
                      <span className="text-xs text-grey-lite">
                        Lesson #{idx + 1}
                      </span>
                      {lesson.frontmatter.time_to_learn !== null && (
                        <div className="flex gap-1 items-center">
                          <ClockIcon className="text-grey-lite w-3.5 h-3.5" />
                          <span className="text-xs text-grey-lite">
                            {!!+hours && `${hours}h`}{" "}
                            {!!+minutes && `${minutes}m`}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              );
            })}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};

export default LessonPagination;
