import React from "react";

import "./LessonOverview.scss";

interface ILessonOverviewProps {
  lessonContents: string[];
}

const LessonOverview: React.FC<ILessonOverviewProps> = ({ lessonContents }) => {
  if (!lessonContents?.length) return null;

  return (
    <div className="p-4 lg:p-6 rounded-2xl bg-brown-500 space-y-4 mt-1.5 mb-8">
      <p className="text-sm font-semibold text-white">
        In this lesson we will:
      </p>
      <ul className="space-y-4 p-0">
        {lessonContents.map((content, idx) => (
          <li
            className="list-none lesson-custom-bullet text-sm font-semibold text-white"
            key={idx}
          >
            {content}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LessonOverview;
