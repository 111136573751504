import React, { useContext } from "react";
import { MDXProvider } from "@mdx-js/react";
import classNames from "classnames";

import { AuthContext } from "../../contexts/AuthContext";

import { ILesson } from "../../templates/category";
import YouTubeEmbed from "../mdx/YouTubeEmbed";
import InformationEmbed from "../mdx/InformationEmbed";
import CopyToClipboard from "../CopyToClipboard";
import FeaturesBanner from "../UI/FeaturesBanner";
import LessonOverview from "./LessonOverview";
import { MDXRenderer } from "gatsby-plugin-mdx";
import LessonPagination from "./LessonPagination";
import Contents from "./Contents";
import LessonCard from "../CoursePage/LessonCard";

interface ILessonContainerProps {
  lesson: {
    frontmatter: ILesson;
    tableOfContents: any;
    body: any;
  };
  bgColor: string[];
  categorySlug: string;
  lessons: Array<{
    frontmatter: ILesson;
  }>;
}

const LessonContainer: React.FC<ILessonContainerProps> = ({
  lesson,
  bgColor,
  lessons,
  categorySlug,
}) => {
  const { isAuthenticated, subscription, loading } = useContext(AuthContext);
  const isBlockedLesson =
    (lesson.frontmatter?.visibility === "members" && !isAuthenticated) ||
    (lesson.frontmatter?.visibility === "premium" && !subscription);
  const currentLesson = lessons.findIndex(
    (el) => el.frontmatter.slug === lesson.frontmatter.slug
  );
  const nextLesson = lessons?.[currentLesson + 1]?.frontmatter;
  const isBlockedNextLesson =
    (nextLesson?.visibility === "members" && !isAuthenticated) ||
    (nextLesson?.visibility === "premium" && !subscription);

  return (
    <div className="flex max-w-[1188px] justify-center gap-6 lg:gap-16 px-6 lg:flex-row-reverse flex-col mx-auto pt-10 lg:pt-16 pb-14 lg:pb-16">
      <div className="lg:max-w-[294px] w-full lg:p-2 gap-6 lg:gap-[18px] flex flex-col-reverse lg:flex-col">
        <LessonPagination lessons={lessons} currentLesson={currentLesson} />
        <Contents
          contents={lesson.tableOfContents?.items ?? []}
          pathname={`/learn/${lesson.frontmatter.category}/lessons/${lesson.frontmatter.slug}`}
        />
      </div>
      <div className="w-full lg:max-w-[830px]">
        <article
          className={classNames("mb-8", {
            "relative overflow-hidden protected-content":
              isBlockedLesson || loading,
          })}
        >
          <LessonOverview
            lessonContents={lesson.frontmatter.lesson_contents ?? []}
          />
          <div className="prose max-w-none w-full mt-1.5">
            <MDXProvider
              components={{
                YouTubeEmbed,
                InformationEmbed,
                pre: CopyToClipboard,
                wrapper: (props) => (
                  <main>
                    {isBlockedLesson && props.children?.length
                      ? props.children.filter((_: any, idx: number) => idx < 3)
                      : props.children}
                  </main>
                ),
              }}
            >
              <MDXRenderer localImages={lesson.frontmatter.embeddedImagesLocal}>
                {lesson.body}
              </MDXRenderer>
            </MDXProvider>
          </div>
        </article>
        {!subscription && lesson.frontmatter.visibility === "premium" && (
          <FeaturesBanner
            title="This lesson requires a premium membership"
            description="Though our content is free, this lesson requires a free membership for access.  Please register using the link below to support our work."
            to="/auth/register"
            buttonLabel="Register"
          />
        )}
        {!isAuthenticated && lesson.frontmatter.visibility === "members" && (
          <FeaturesBanner
            title="This Lesson Requires A Free Membership"
            description="Though our content is free, this lesson requires a free membership for access.  Please register using the link below to support our work."
            to="/auth/register"
            buttonLabel="Register"
          />
        )}
        {nextLesson && (
          <div className="mt-10 lg:mt-[72px] space-y-2">
            <span className="text-sm text-grey-400">Next Lesson:</span>
            <LessonCard
              backgroundColor={bgColor}
              lessonNumber={currentLesson + 1}
              title={nextLesson.title}
              timeToLearn={nextLesson.time_to_learn ?? 0}
              description={nextLesson.custom_excerpt ?? nextLesson.excerpt}
              slug={nextLesson.slug}
              categorySlug={categorySlug}
              isBlocked={isBlockedNextLesson}
            />
 
            <br />
            <br />
            <br />
            <br />

            {!(lesson.frontmatter.visibility === "members") &&
              !(lesson.frontmatter.visibility === "premium") && (

                <FeaturesBanner
                  title={`Work With The Experts In Real-Time Analytics & AI`}
                  description="we help enterprise organisations deploy powerful real-time Data, Analytics and AI solutions based on ClickHouse, the worlds fastest open-source database."
                  to="/"
                  buttonLabel="Tell Me More!"
                />
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LessonContainer;
