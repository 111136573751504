import React from "react";
import { graphql } from "gatsby";

import DefaultLayout from "../components/layouts/DefaultLayout";
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
import SEO from "../components/layouts/SEO";
import { ILesson } from "./category";
import { ICourse } from "../components/Header";
import Hero from "../components/LessonPage/Hero";
import LessonContainer from "../components/LessonPage/LessonContainer";
import { ISiteMetaData } from "../types";

deckDeckGoHighlightElement();
interface ILessonDetailsProps {
  data: {
    lesson: {
      frontmatter: ILesson;
      tableOfContents: any;
      body: any;
    };
    lessons: {
      nodes: Array<{
        frontmatter: ILesson;
      }>;
    };
    posts: {
      nodes?: any[];
    };
    category: {
      frontmatter?: ICourse;
    };
    site: {
      siteMetadata: ISiteMetaData;
    };
  };
  pageContext: {
    currentLesson: number;
    pagination: {
      next: {
        url: string;
        title: string;
        visibility: string;
        order: number;
      };
      prev: {
        url: string;
        title: string;
        visibility: string;
        order: number;
      };
    };
  };
}

const LessonDetails: React.FC<ILessonDetailsProps> = ({
  data,
  pageContext,
}) => {
  const lesson = { ...data.lesson };
  const lessons = data?.lessons?.nodes;
  const category = { ...data.category?.frontmatter };
  const siteUrl = data.site.siteMetadata.siteUrl;
  const categorySlug = lesson.frontmatter.category[0];

  return (
    <>
      <SEO
        title={`${lesson.frontmatter.title} | Ensemble`}
        url={`${siteUrl}/learn/${lesson.frontmatter.slug}`}
        desc={
          lesson.frontmatter.custom_excerpt
            ? lesson.frontmatter.custom_excerpt
            : lesson.frontmatter.excerpt
        }
        ogImage={`${siteUrl}/assets/img/ensemble_background.png`}
      />
      <DefaultLayout>
        <Hero
          title={lesson.frontmatter.title}
          categoryName={category.title ?? ""}
          categorySlug={categorySlug}
          backgroundColor={category.background_color}
          order={pageContext.currentLesson}
          prevLesson={pageContext.pagination.prev}
          nextLesson={pageContext.pagination.next}
        />
        <LessonContainer
          lessons={lessons}
          lesson={lesson}
          bgColor={category.background_color ?? []}
          categorySlug={categorySlug}
        />
      </DefaultLayout>
    </>
  );
};

export const query = graphql`
  query ($slug: String!, $category_slug: String!) {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }

    category: mdx(
      slug: { eq: $category_slug }
      fields: { source: { eq: "categories" } }
    ) {
      frontmatter {
        title
        background_color
        background_image
        short_title
      }
    }

    lessons: allMdx(
      filter: {
        fields: { source: { in: ["content"] } }
        frontmatter: { category: { in: [$category_slug] } }
      }
      limit: 5000
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      nodes {
        frontmatter {
          title
          slug
          category
          time_to_learn
          visibility
          custom_excerpt
          excerpt
        }
      }
    }

    lesson: mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        category
        visibility
        slug
        custom_excerpt
        lesson_contents
        lesson_video
        lesson_author
        next_lesson_description
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      excerpt(pruneLength: 160)
      body
      tableOfContents
    }

    posts: allMdx(
      filter: { fields: { source: { eq: "blog" } } }
      sort: { fields: frontmatter___date_updated, order: DESC }
    ) {
      nodes {
        frontmatter {
          date_updated
          title
          tag
          excerpt
          featured_image {
            childImageSharp {
              gatsbyImageData(width: 1024)
            }
          }
          background_color
        }
        id
        slug
        excerpt
      }
    }
  }
`;

export default LessonDetails;
